<template>
  <div>
    <el-result title="Not PDF" subTitle="抱歉，文件不存在">
      <template slot="icon">
        <el-image
          src="/pdf.png"
        ></el-image>
      </template>
      <template slot="extra">
        <el-button type="primary" size="medium" @click="backPage()">返回</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
  methods: {
    backPage(){
      window.history.go(-2);
    }
  }
};
</script>
  
<style>
</style>